<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Password </strong><small>Edit Password</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePass">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="password"
                type="password"
                placeholder="*****************"
                label="Password Sekarang"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="passnew"
                type="password"
                label="Password Baru"
                placeholder="Input password baru"
              />  
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="passnew2"
                type="password"
                label="Konfirmasi Password Baru"
                placeholder="Input password baru"
              />
              </CCol>     
            </CRow>            

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/dashboard">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Password gagal diinput.
    </CModal>

    <CModal color="danger" title="Error" :show.sync="myPassNotMatch" size="sm">
      <b>Gagal</b>! Password anda tidak cocok.
    </CModal>

    <CModal color="danger" title="Error" :show.sync="myError" size="sm">
      <b>Gagal</b>! Password anda gagal diperbaharui.
    </CModal>

    <CModal color="success" title="Error" :show.sync="mySukses" size="sm">
      <b>Sukses</b>! Password anda berhasil diperbaharui.
    </CModal>
   
  </div>
 
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      // username : "",
      myPassNotMatch : false,
      mySukses : false,
      myError : false,
      id_user : "",
      password : "",
      passnew : "",
      passnew2 : "",
      // level : "",
      selected: [], // Must be an array reference!
      // kegiatan: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  // this.showPass();
  },
  methods: {

    updatePass: function(event){

      let us = JSON.parse(this.$store.state.users);

      // Simple POST request with a JSON body using axios
      const ubahpassword = { 
                          password: this.password, 
                          passbaru: this.passnew,
                          username : us.id
                          };
      // alert(JSON.stringify(user));
      if(this.passnew != this.passnew2){
        this.myPassNotMatch = true; 
      }
      else{
        axios.post(process.env.VUE_APP_BASE_URL+"gantipassword", ubahpassword)
        .then((response) => {
            console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.mySukses = true;
            }
            else{
              this.myError = true;
            }
            
        })
      }
      
    },

    // showPass: function(event){
    //   // Simple POST request with a JSON body using axios
      
    //   let us = JSON.parse(this.$store.state.users);
      
    //   axios.get(process.env.VUE_APP_BASE_URL+"myprofile/"+us.id_user+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
            
    //           this.password=response.data.password;
    //           // this.email=response.data.email;
    //           // this.username=response.data.username;
            
    //     })
    // }

  }
}
</script>